import React, { useRef } from 'react';
import styled from 'styled-components';
import Navbar, { SCROLL_BEHAVIOR } from './Navbar';
import Footer from './Footer';

type Props = React.HTMLAttributes<HTMLDivElement>;

export const WorkLayout: React.FunctionComponent<Props> = ({ children }) => 
{
    const ref = useRef<HTMLDivElement>(null);
    return (
        <>
            <Navbar scrollBehavior={{ mobile: SCROLL_BEHAVIOR.OPAQUE_AFTER_SCROLL }} />
            <Container id="container" ref={ref}>
                {children}
                <Footer />
            </Container>
        </>
    );
};

const Container = styled.div`
    z-index: 0;
    width : 100%;
     overflow-x: hidden;
`;