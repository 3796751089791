import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { MediaQuery } from 'core/config';
import { useRenderSphere } from 'view/hooks';
import { SPHERE_COLOURS } from 'core/utilities';
import { CanonicalTag } from 'view/components/common';
import { Hero, Reel, WhatWeDo, FeaturedProjects, Awards } from './sections';
import { Services } from './sections/Services';
import { useHistory } from 'react-router';

export enum HomePageSections
{
    HERO    = 0,
    REEL = 1,
    WHATWEDO  = 2,
    SERVICES = 3,
    CONTACT = 4
}

export const HomePage: React.FunctionComponent = () => 
{
    const history = useHistory();
    const [ scrollPosition, setScrollPosition ] = useState(0);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const firstDivRef = useRef<HTMLDivElement>(null);
    const secondDivRef = useRef<HTMLDivElement>(null);
    const thirdDivRef = useRef<HTMLDivElement>(null);

    useRenderSphere(canvasRef, true, SPHERE_COLOURS.HOME_PAGE);

    const navigateToOurWork = () => 
    {
        history.push('/work');
    };

    const handleScroll = () => 
    {
        setScrollPosition(window.scrollY);
    };
        
    useEffect(() => 
    {
        window.addEventListener('scroll', handleScroll, true);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const firstDiv = firstDivRef.current as HTMLDivElement;
    const secondDiv = secondDivRef.current as HTMLDivElement;
    const canvas = canvasRef.current as HTMLCanvasElement;
    const totalHeight = firstDiv?.clientHeight + secondDiv?.clientHeight  || 1;
    const percentageScrolled = scrollPosition / totalHeight;
    const scrollX = Math.min(percentageScrolled * 1.2, 1.2) * (firstDiv?.clientWidth - canvas?.clientWidth) * -1;
    const scrollY = percentageScrolled > 1 ? (scrollPosition - totalHeight) * -1 : 0;
    const translate = { x: scrollX , y: scrollY };

    return (
        <Container>
            <CanonicalTag url="https://www.speer.io/" />
            <div ref={firstDivRef}>
                <Hero navigateToSection={navigateToOurWork} />
            </div>
            <div ref={secondDivRef}>
                <Reel />
            </div>
            <div ref={thirdDivRef}>
                <WhatWeDo />
            </div>
            <Services />
            <FeaturedProjects navigateToWork={navigateToOurWork} />
            <Awards />

            <CanvasWrapper translateX={translate.x} translateY={translate.y}>
                <Canvas
                    ref={canvasRef}
                    id="sphere"
                    className={'fade-in-sphere'}
                />
            </CanvasWrapper>
        </Container>
    );
};

const Container = styled.div`
    overflow: visible;
`;

const CanvasWrapper = styled.div<{ translateX: number, translateY: number }>`
    overflow: hidden;
    z-index: -1;

    @media ${MediaQuery.mobileS} {
        position: absolute;
        top: 15%;
        right: 0;
        width: 100vw;
        aspect-ratio: 1;
        height: auto;
    }
    @media ${MediaQuery.tablet} {
        top: -10%;
        right: -20%;
    }

    @media ${MediaQuery.laptopS} {
        position: fixed;
        top: 0;
        right: 0;
        width: auto;
        height: 100%;
        transform: ${({ translateX, translateY }) => `translate(${translateX}px, ${translateY}px)`};
        transition: top 0.1s linear;
    }
`;

const Canvas = styled.canvas`
    overflow: hidden;
    display: block;
    position: absolute;
    top: 45px;
    right: 0;
    width: 100%;
    height: 100%;
    transform: translate(25%, -25%);
    opacity: 0;
    transition: opacity 0.7s ease-in;

    &.fade-in-sphere {
        opacity: 1;
    }

    @media ${MediaQuery.tablet} {
        width: 100%;
        height: 100%;
        transform: none;
    }
`;



